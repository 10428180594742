import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { Layout, CategoryFilter } from '../components'
import moment from 'moment'
import { forEach } from 'lodash'
import { useSearchParam, useMount } from 'react-use';

import { container, padding, wrapper, bgImage, heading, linkText, hoverState } from '../styles/global'
import { scrollToTop } from '../utils';
import { media } from '../styles/utils';

const Journal = (props) => {
    let data = useStaticQuery(query).allWordpressNews.nodes;
    
    if (props.previewData) {
        data = props.previewData
    }
    
    const queryCat = useSearchParam('category');
    const [activeCategory, setActiveCategory] = useState(null)  

    // Update activeCategory based on query string

    useMount(() => {
        if (queryCat !== activeCategory) setActiveCategory(queryCat)
    })

    // Update query string on activeCategory change

    useEffect(() => {
        if (activeCategory && queryCat !== activeCategory) {
            history.pushState({}, '', `${location.pathname}?category=${activeCategory}`)
            scrollToTop()
        }

        if (!activeCategory) {
            history.pushState({}, '', location.pathname)
            scrollToTop()
        }
    }, [activeCategory])

    const matchCategory = (cats) => {
        if (!activeCategory) return true

        let match = false;
        
        forEach(cats, (cat) => {
            if (cat.slug == activeCategory) match = true
        })

        return match
    }

    const renderArticles = () => {
        const items = data.map((item, i) => {
            if (!matchCategory(item.cats)) return;
            const acf = JSON.parse(item.acf_json);

            return (
                <Article 
                    key={i}
                >
                    <TitleCategory>
                        <Link
                            to={`/journal/${item.slug}`}
                        >
                            <Heading>{item.title}</Heading>
                        </Link>

                        <Categories>
                            {item.cats.map((cat, i) => {
                                return (
                                    <Category
                                        onClick={() => setActiveCategory(cat.slug)}
                                    >
                                        {cat.name}
                                    </Category>
                                )
                            })}
                        </Categories>
                    </TitleCategory>

                    <Content>
                       {acf.hero_image && (
                            <Image
                                key={acf.hero_image.sizes.large}
                                src={acf.hero_image.sizes.large}
                            >
                                {(src, loading) => {
                                    return (
                                        <Link
                                            to={`/journal/${item.slug}`}
                                        >
                                            <BGImage 
                                                image={src} 
                                                style={{opacity: loading ? 0 : 1}}  
                                            />
                                        </Link>
                                    )
                                }}
                            </Image> 
                       )}         

                       <Info> 
                           <Date>
                                {moment.unix(item.publishedDateUnix).format('MM.DD.YYYY')}
                           </Date>

                            <Overview>
                                <Description
                                    dangerouslySetInnerHTML={{__html: acf.listing_description}}
                                />

                                <InternalLink
                                    to={`/journal/${item.slug}`}
                                >
                                    Continue reading
                                </InternalLink>
                            </Overview>
                        </Info>         
                    </Content>
                </Article>
            )
        })
        
        return items;
    }

	return (
		<Layout
            callToAction={true}
        >
            <Wrapper>
                <Container>
                    <Filter
                        activeCategory={activeCategory}
                        onChange={(cat) => setActiveCategory(cat)}
                    />

                    {renderArticles()}
                </Container>
            </Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const InternalLink = styled(Link)`
    ${linkText}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    ${wrapper}
    width: 100%;
    padding-bottom: 120px;
`

const Container = styled.div`
	${container}
	${padding}
    flex-direction: column;
`

// Filter

const Filter = styled(CategoryFilter)`
    position: absolute;
    top: 38px;
    z-index: 2;
    transform: translateX(300px);

    ${media.phone`
        position: relative;
        top: auto;
        left: auto;
        margin-bottom: 32px;
        transform: none;
    `}
`

// Article Categories

const Categories = styled.div`
    margin-top: 37px;

    ${media.phone`
        margin-top: 7px;
    `}
`

const Category = styled.div`
    ${linkText}
    cursor: pointer;
`

// Article

const Content = styled.div``
const TitleCategory = styled.div`
    margin-right: 96px;

    ${Heading} {
        ${heading}
    }
`

const Date = styled.div``
const Overview = styled.div``

const Article = styled.div`
    display: flex;
    margin-bottom: 80px; 

    ${TitleCategory} {
        flex: 0 1 40%;
    }

    ${Content} {
        flex: 1 0 60%;
    }

    ${TitleCategory} {
        > ${Heading} {
            ${heading}
        }
    }

    ${Content} {
        padding-bottom: 27px;
        border-bottom: 1px solid rgba(0,0,0, 0.4);

        ${BGImage} {
            height: 580px;
        }

        ${Info} {
            display: flex;
            margin-top: 35px;

            ${Date} {
                ${linkText}
                flex: 1 0 25%;
            }

            ${Overview} {
                ${Description} {
                    &, p {
                        ${linkText}
                    }
                }
         
                ${InternalLink} {
                    margin-top: 30px;
                    color: black;
                    display: flex;
                }
            }
        }
    }

    ${media.tabletPortrait`
        ${Content} {
            flex: 1 0 65%;
        }
    `}

    ${media.phone`
        flex-direction: column;
        margin-bottom: 60px; 

        ${Content} {
            margin-top: 44px;
            padding-bottom: 40px;

            ${BGImage} {
                height: 232px;
            }

            ${Info} {
                flex-direction: column;

                ${Date} {
                    font-size: 14px;
                }

                ${Description} {
                    margin-top: 24px;

                    ${InternalLink} {
                        margin-top: 20px;
                    }
                }
            }
        }
    `}
`


export const query = graphql`
    query {
        allWordpressNews {
            nodes {
                acf_json
                title
                slug
                publishedDateUnix
                cats {
                    slug
                    name
                }
            }
        }
    }
`


export default Journal
